import React, { useState } from 'react';
import styled from 'styled-components';
import { ColInSection, Section } from '../components/layoutComponents';
import selfCateringData from '../data/selfCateringData.json';
import { Link } from 'gatsby';
import {
  AiOutlineArrowRight,
  AiOutlineCalendar,
  AiOutlinePicture,
} from 'react-icons/ai';
import { useTheme } from 'styled-components/macro';
import { theme } from '../styles/theme';
import Button from '../styles/button';
import { useContext } from 'react';
import { Context } from '../components/rootElement';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import ModalComponent from '../components/layoutComponents/modal';
import { wobble } from '../styles/animations';
import ListElWithIcon from '../components/listElWithIcon';
// import { SEO, useSEO } from "gatsby-plugin-seo";

const StSection = styled(Section)`
  &:nth-child(4n) {
    background-color: ${({ theme }) => theme.babyPowder()};
    background: radial-gradient(circle, white 0%, ${theme.platinum()} 100%);
  }
  &:nth-child(4n + 1) {
    background-color: ${({ theme }) => theme.yellowGreenCrayola()};
    background: radial-gradient(circle, ${theme.yellowGreenCrayola()} 0%, ${theme.greenRYB()} 130%);
    /* color: white; */
  }
  &:nth-child(4n + 2) {
    background-color: ${({ theme }) => theme.babyPowder()};
    background: radial-gradient(circle, white 0%, ${theme.platinum()} 100%);
  }
  &:nth-child(4n + 3) {
    background-color: ${({ theme }) => theme.logoYellow()};
    /* background: radial-gradient(circle, ${theme.wildBlueYonder()} 0%, ${theme.littleBoyBlue()} 140%); */
    color: ${theme.jet()};
    /* text-shadow: 0px 0px 5px ${theme.littleBoyBlue()}; */
  }
  &:nth-child(1) {
    margin-top: ${({ theme }) => theme.headerHeightBig}px;
  }
`;
const PicStub = styled.div`
  width: 100%;
  height: 350px;
  border: 2px dashed lightcoral;
  border-radius: 5px;
`;
const StColInSection = styled(ColInSection).attrs({
  col: 2,
})`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  & * {
    color: inherit;
  }
`;
const StButton = styled(Button)`
  text-align: center;
  margin: 0px auto;
  &&& {
    color: ${({ theme }) => theme.jet()};
  }
  @media only screen and (min-width: 1024px) {
    max-width: 50%;
  }
`;
const StColInSection2 = styled(ColInSection)`
  font-family: ${({ fontFamily }) => fontFamily || 'sacramento'};
  font-size: 2em;
  text-align: center;
  color: ${({ theme }) => theme.jet()};
  @media only screen and (min-width: 1024px) {
    padding: 0px 100px;
    font-size: 4em;
  }
`;

const AnimColInSection = styled(ColInSection)`
  animation: ${wobble} 1.6s infinite both;
`

const RoomsPage = () => {
  const { headerHeightBig, platinum, littleBoyBlue, wildBlueYonder, fireOpal, logoYellow, jet } = useTheme(theme);
  const [unitClicked, setUnitClicked] = useState(false);
  const [modalContent, setModalContent] = useState(false);
  const { unitsArr } = useContext(Context);

  const unitClickHandler = (unit) => {
    setUnitClicked(true);
    setModalContent(unit);
  };

  const selfCateringData = unitsArr.filter(
    (node) => node.unitType === 'Self Catering Unit'
  );

  return (
    <>
      {/* <SEO
        title="Self Catering Units - Touch of Class Guest House"
        description="These suites are fully self catered and are perfect for long business trips or holidays"
      /> */}
      <Section
        style={{
          marginTop: headerHeightBig,
          // textShadow: `0px 0px 10px ${littleBoyBlue()}`,
        }}
        backgroundColor={logoYellow()}
      >
        <StColInSection2 col={1}>Self Catering Units</StColInSection2>
      </Section>
      {selfCateringData.map((unit, idx) => {
        return (
          <StSection key={`unit-${idx}`} alignItems='stretch'>
            <StColInSection>
              {unit.images.map((image, idx) =>
                image.title.includes('cover') ? (
                  <GatsbyImage
                    key={`unit-img${idx}`}
                    image={getImage(image.gatsbyImageData)}
                    alt=''
                    style={{
                      boxShadow: '0px 0px 15px -6px #000000',
                      borderRadius: 5,
                    }}
                  />
                ) : null
              )}
              {/* <PicStub /> */}
              <p style={{ marginTop: 40 }}>{unit.description.description}</p>
              <StButton
                type='button'
                onClick={() => unitClickHandler(unit)}
                style={{ marginTop: 16 }}
              >
                <AiOutlinePicture /> View Images
              </StButton>
            </StColInSection>
            <StColInSection>
              <h3>{unit.name}</h3>
              <h4>Features</h4>
              <ul>
                {unit.roomFeatures.map((feat, index) => (
                  <ListElWithIcon key={`unit-${idx}-feat${index}`} feature={feat}/>
                ))}
              </ul>
              <StButton as={Link} to='https://www.nightsbridge.co.za/bridge/book?bbid=17738'>
                <AiOutlineCalendar /> Book Online
              </StButton>
            </StColInSection>
          </StSection>
        );
      })}
      <Section backgroundColor={platinum()}>
        <AnimColInSection
          col={1}
          style={{ fontSize: '1.5em', textAlign: 'center', textTransform: 'uppercase' }}
          color={fireOpal()}
        >
          <Link to='/rooms'>
            View our Guesthouse Rooms? <AiOutlineArrowRight />
          </Link>
        </AnimColInSection>
      </Section>
      <ModalComponent
        show={unitClicked}
        setShow={setUnitClicked}
        content={modalContent}
      />
    </>
  );
};

export default RoomsPage;
